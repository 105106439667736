import type { CSSProperties } from 'react';
import type { Player, PlayerGroup } from '../models';
import { Box, Typography } from '@mui/material';
import { useDrop } from 'react-dnd';
import { PlayerBox } from './PlayerBox';

interface GroupContainerProps {
  group: PlayerGroup;
  onDrop: (player: Player) => void;
}

const groupContainerStyles: CSSProperties = {
  border: 'none',
  padding: 2,
  borderRadius: 2,
  minHeight: 50,
};

const BG_COLORS: Record<string, string> = {
  Grön: 'rgba(29, 145, 40, 0.8)',
  Rosa: 'rgba(238, 78, 224, 0.6)',
  Gul: 'rgba(206, 196, 7, 0.8)',
  Blå: 'rgba(14, 77, 213, 0.6)',
};

export const GroupContainer = ({ group, onDrop }: GroupContainerProps) => {
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: [...Object.keys(BG_COLORS).filter(x => x !== group.color)],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  const styles = {
    backgroundColor: BG_COLORS[group.color],
    border: 'none',
    padding: '16px',
  };
  if (isActive) {
    styles.backgroundColor = 'rgb(0, 191, 29)';
    styles.border = '3px dashed rgb(58, 58, 58)';
    styles.padding = `${16 - 3}px`;
  } else if (canDrop) {
    styles.backgroundColor = 'rgba(78, 215, 238, 0.6)';
    styles.border = '3px dashed rgb(58, 58, 58)';
    styles.padding = `${16 - 3}px`;
  }

  return (
    <Box ref={dropRef} sx={{ ...groupContainerStyles, ...styles }}>
      <Typography variant="h4">
        {group.color} ({group.players.length})
      </Typography>
      {group.players.map((player) => (
        <PlayerBox key={player.id} player={player} color={group.color} />
      ))}
    </Box>
  );
};
