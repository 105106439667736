import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { sendMultipleMessages } from './services/whatsAppService';
import { Player, PlayerGroup } from './models';
import playerData from './utils/players';
import { Typography } from '@mui/material';
import { randomizePlayers } from './utils/randomizer';
import { GroupContainer } from './components/GroupContainer';
import WhatsAppDialog from './components/WhatsAppDialog';
import sortPlayers from './utils/sortPlayers';
import { getTodaysGroups, saveGroups } from './services/groupService';

const App = () => {
  const didMount = useRef(false);
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const { players } = playerData;
  // const [manuallyChanged, setManuallyChanged] = useState<boolean>(false);
  // const [fromDb, setFromDb] = useState<boolean>(false);
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<number[]>(
    players.map((x) => x.id)
  );
  const [noOfGroups, setNoOfGroups] = useState<number>(3);
  const [groups, setGroups] = useState<PlayerGroup[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  // const initRecaptchaToken = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //     return;
  //   }
  //   const token = await executeRecaptcha('getGroupsFromDb');
  //   setRecaptchaToken(token);
  // }, [executeRecaptcha]);

  // useEffect(() => {
  //   initRecaptchaToken();
  // }, [initRecaptchaToken]);

  const randomizeGroups = async () => {
    const attendingPlayers = players.filter((player) =>
      selectedPlayerIds.includes(player.id)
    );
    const playerGroups = randomizePlayers(attendingPlayers, noOfGroups);
    setGroups(playerGroups);

    // const playersForPrint = playerGroups.map(pg => pg.players.map(p => `${p.firstName} ${p.lastName}`));

    await saveGroups(playerGroups);
  };

  useEffect(() => {
    (async () => {
      const todaysGroups = await getTodaysGroups();
      if (todaysGroups) {
        // setFromDb(true);
        setGroups(todaysGroups);
        setSelectedPlayerIds(
          todaysGroups.map((x) => x.players.map((p) => p.id)).flat()
        );
        setNoOfGroups(todaysGroups.length);
      } else {
        await randomizeGroups();
      }
    })();
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    (async () => {
      await randomizeGroups()
    })();
  }, [noOfGroups]);

  useEffect(() => {
    if (groups.length === 0) return;

    const allPlayersInGroup = groups.map((g) => g.players).flat();
    if (allPlayersInGroup.length < selectedPlayerIds.length) {
      const newPlayerId = selectedPlayerIds.find(
        (pId) => !allPlayersInGroup.find((pl) => pl.id === pId)
      );
      const newPlayer = players.find((p) => p.id === newPlayerId);
      if (newPlayer) {
        const smallestGroup = groups.reduce(
          (group: PlayerGroup, curr: PlayerGroup) => {
            if (group.players?.length < curr.players?.length) {
              return group;
            } else {
              return curr;
            }
          },
          {} as PlayerGroup
        );
        if (!smallestGroup) return;
        smallestGroup.players.push(newPlayer);
        const newGroups = groups
          .reduce((groups: PlayerGroup[], curr: PlayerGroup) => {
            if (curr.color === smallestGroup.color) {
              groups.push(smallestGroup);
            } else {
              groups.push(curr);
            }
            return groups;
          }, [])
          .map((g) => ({
            ...g,
            players: sortPlayers(g.players),
          }));

        setGroups(newGroups);
        (async () => await saveGroups(newGroups))();
      }
    } else if (allPlayersInGroup.length > selectedPlayerIds.length) {
      const playerToRemove = allPlayersInGroup.find(
        (p) => !selectedPlayerIds.find((pid) => pid === p.id)
      );
      if (!playerToRemove) return;
      const groupToRemoveFrom = groups.find((g) =>
        g.players.find((p) => p.id === playerToRemove.id)
      );
      if (!groupToRemoveFrom) return;
      groupToRemoveFrom.players = groupToRemoveFrom.players.filter(
        (p) => p.id !== playerToRemove.id
      );
      const newGroups = groups
        .reduce((groups: PlayerGroup[], curr: PlayerGroup) => {
          if (curr.color === groupToRemoveFrom.color) {
            groups.push(groupToRemoveFrom);
          } else {
            groups.push(curr);
          }
          return groups;
        }, [])
        .map((g) => ({
          ...g,
          players: sortPlayers(g.players),
        }));

      setGroups(newGroups);
      (async () => await saveGroups(newGroups))();
    }
  }, [selectedPlayerIds, players, groups]);

  const handlePlayerDrop = async (player: Player, groupIndex: number) => {
    const newGroups = groups.reduce(
      (group: PlayerGroup[], curr: PlayerGroup, idx: number) => {
        if (idx === groupIndex) {
          curr.players.push(player);
        } else {
          curr.players = curr.players.filter((x) => x.id !== player.id);
        }
        curr.players = sortPlayers(curr.players);
        group[idx] = curr;
        return group;
      },
      []
    );

    setGroups(newGroups);
    await saveGroups(newGroups);
  };

  const createMessages = async () => {
    const getName = (player: Player) =>
      `${player.firstName} ${player.lastName.slice(0, 1)}`;
    const groupMessages = groups.reduce(
      (messages: string[], curr: PlayerGroup) => {
        messages.push(`${curr.color}: ${curr.players.map(getName).join(', ')}`);
        return messages;
      },
      []
    );

    setMessages(['Hej! Dagens grupper är:', ...groupMessages]);
    setDialogOpen(true);
  };

  const handleAttendingPlayersChange = (
    event: SelectChangeEvent<typeof selectedPlayerIds>
  ) => {
    setSelectedPlayerIds(event.target.value as number[]);
  };

  const handleNoOfGroupsChanged = async (event: SelectChangeEvent<number>) => {
    setNoOfGroups(+event.target.value);
    // (async () => {
    //   await randomizeGroups();
    // })();
  };

  const sendMessages = async (recaptchaToken: string) => {
    await sendMultipleMessages({ messages, token: recaptchaToken });
  };

  return (
    <>
      <Grid xs={12} md={10} mdOffset={1}>
        <Grid xs={12} spacing={1}>
          <Typography variant="h4">
            Vilka kommer? ({selectedPlayerIds.length})
          </Typography>{' '}
          <FormControl sx={{ mr: 1 }}>
            <InputLabel id="checkbox-label">Spelare</InputLabel>
            <Select
              labelId="checkbox-label"
              id="checkbox"
              multiple
              value={selectedPlayerIds}
              onChange={handleAttendingPlayersChange}
              input={<OutlinedInput label="Spelare" />}
              renderValue={(ids: number[]) => {
                const selectedPlayers = players.filter((x) =>
                  ids.includes(x.id)
                );
                return `${selectedPlayers
                  .map((player) => player?.firstName)
                  .join(', ')
                  .slice(0, 25)}...`;
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 6,
                    width: 250,
                  },
                },
                autoFocus: false,
              }}
            >
              {players.map((player) => (
                <MenuItem key={player.id} value={player.id}>
                  <Checkbox
                    checked={selectedPlayerIds.indexOf(player.id) > -1}
                  />
                  <ListItemText
                    primary={`${player.firstName} ${player.lastName}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 100, textAlign: 'center' }}>
            <InputLabel id="antal_grupper">Antal grupper</InputLabel>
            <Select
              labelId="antal_grupper"
              value={noOfGroups}
              label="Antal grupper"
              onChange={handleNoOfGroupsChanged}
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container>
          {groups.map((group, idx) => (
            <Grid key={idx} xs={12} md={12 / noOfGroups}>
              <GroupContainer
                group={group}
                onDrop={(player) => handlePlayerDrop(player, idx)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid xs={12}>
          <Button variant="contained" onClick={createMessages}>
            Skicka i WhatsApp
          </Button>
        </Grid>
      </Grid>
      <WhatsAppDialog
        messages={messages}
        open={dialogOpen}
        setOpen={setDialogOpen}
        sendMessages={sendMessages}
      />
    </>
  );
};

export default App;
