import { Player, PlayerGroup } from '../models';
import sortPlayers from './sortPlayers';

export const colors = {
  '0': 'Grön',
  '1': 'Rosa',
  '2': 'Gul',
  '3': 'Blå',
} as Record<string, string>;

let chunkCounter = 0;
const chunkify = (array: Player[], noOfGroups: number) => {
  const chunkSize = array.length / noOfGroups;
  chunkCounter++;
  return array
    .map((_, i) => array.slice(i * chunkSize, i * chunkSize + chunkSize))
    .filter((x) => x.length)
    .sort((a, b) => {
      if (chunkCounter % noOfGroups === 0) {
        return a.length - b.length;
      }
      return b.length - a.length;
    });
};

const shuffleArray = (array: Player[]) => {
  const shuffledArray = [...array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = shuffledArray[i];
    shuffledArray[i] = shuffledArray[j];
    shuffledArray[j] = temp;
  }

  return shuffledArray;
};

export const randomizePlayers = (
  players: Player[],
  noOfGroups: number
): PlayerGroup[] => {
  const advanced = players.filter((x) => x.level === 'S');
  const medium = players.filter((x) => x.level === 'M');
  const easy = players.filter((x) => x.level === 'L');

  const advancedChunks = chunkify(shuffleArray(advanced), noOfGroups);
  const mediumChunks = chunkify(shuffleArray(medium), noOfGroups);
  const easyChunks = chunkify(shuffleArray(easy), noOfGroups);

  const groups = advancedChunks.reduce(
    (group: PlayerGroup[], chunk: Player[], idx: number) => {
      const m = mediumChunks[idx];
      const l = easyChunks[idx];
      group.push({
        color: colors[idx],
        players: [...chunk, ...m, ...l],
      });

      return group;
    },
    []
  );

  const sortedGroups = groups.map(group => ({
    ...group,
    players: sortPlayers(group.players),
  }));

  return sortedGroups;
};
