import type { TransitionProps } from '@mui/material/transitions';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { forwardRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Avatar from '@mui/material/Avatar';
import { teal } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import { ChatBox, ReceiverMessage, SenderMessage } from 'mui-chat-box';
import Box from '@mui/material/Box';

const Receiver = styled(ReceiverMessage)`
  > .MuiPaper-root {
    background-color: #e6e6e6;
    color: red;
  }
`;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface WhatsAppDialogProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  messages: string[];
  sendMessages: (recaptchaToken: string)=> Promise<void>;
}

const WhatsAppDialog = ({
  open,
  setOpen,
  messages,
  sendMessages,
}: WhatsAppDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setIsLoading] = useState(false);

  const sendWhatsAppMessage = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    setIsLoading(true);

    const token = await executeRecaptcha('sendWhatsAppMessage');
    await sendMessages(token);
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {'Skicka till "EIK P2011-2"'}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              borderRadius: 1,
              p: 2,
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            }}
          >
            <ChatBox>
              <Receiver sx={{ color: 'red' }} avatar={<Avatar>S</Avatar>}>
                Bla bla bla
              </Receiver>
              <ReceiverMessage avatar={<Avatar>A</Avatar>}>
                Pip pip pip
              </ReceiverMessage>
              <ReceiverMessage avatar={<Avatar>L</Avatar>}>
                .....
              </ReceiverMessage>
              {messages.map((message: string, idx: number) => (
                <SenderMessage
                  key={idx}
                  avatar={<Avatar sx={{ bgcolor: teal[500] }}>A</Avatar>}
                >
                  {message}
                </SenderMessage>
              ))}
            </ChatBox>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} disabled={isLoading}>Avbryt</Button>
          <Button onClick={sendWhatsAppMessage} disabled={isLoading}>Skicka</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WhatsAppDialog;
