import Chip from '@mui/material/Chip';
import type { Player } from '../models';
import { useDrag } from 'react-dnd';

interface PlayerBoxProps {
  player: Player;
  color: string;
}

export const PlayerBox = ({ player, color }: PlayerBoxProps) => {
  const [{ opacity, isDragging }, dragRef] = useDrag(
    () => ({
      type: color,
      item: player,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [player]
  );

  return (
    <Chip
      ref={dragRef}
      key={player.id}
      sx={{ m: 1, opacity, '&:hover': { cursor: isDragging ? 'grabbing' : 'grab' } }}
      label={`${player.firstName} ${player.lastName.slice(0, 1)}`}
    />
  );
};
