import axios from 'axios';
import {
  SendMessageRequest,
  SendMessageResponse,
  SendMultipleMessageRequest,
} from '../models';
import config from '../utils/config';

const BASE_URL = config.environment !== 'production' ? 'http://localhost:4444' : '';

export const sendMessage = async ({
  message,
  token,
}: SendMessageRequest): Promise<SendMessageResponse | null> => {

  const { data } = await axios.post<SendMessageResponse>(
    `${BASE_URL}/api/messages`,
    {
      message,
      token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};

export const sendMultipleMessages = async ({
  messages,
  token,
}: SendMultipleMessageRequest): Promise<SendMessageResponse | null> => {

  const { data } = await axios.post<SendMessageResponse>(
    `${BASE_URL}/api/messages/batch`,
    {
      messages,
      token,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return data;
};
