import { Player } from "../models";

const sortMap = {
  S: 1,
  M: 0,
  L: -1
} as Record<string, number>;

const sortPlayers = (players: Player[]) => {
  return players.sort((a: Player, b: Player) => {
    if (sortMap[a.level] > sortMap[b.level]) return -1;
    if (sortMap[a.level] === sortMap[b.level]) return 0;
    if (sortMap[a.level] < sortMap[b.level]) return 1;
    return 0;
  })
};

export default sortPlayers;
