const playerData = {
  "players": [
    {
      "id": 1,
      "firstName": "Alexander",
      "lastName": "Pang Aldrin",
      "level": "L",
      "shirtNumber": 4
    },
    {
      "id": 2,
      "firstName": "Emil",
      "lastName": "Markusson",
      "level": "M",
      "shirtNumber": 85
    },
    {
      "id": 3,
      "firstName": "Henning",
      "lastName": "Fredin",
      "level": "M",
      "shirtNumber": 12
    },
    {
      "id": 4,
      "firstName": "Hugo",
      "lastName": "Nyberg",
      "level": "S",
      "shirtNumber": 10
    },
    {
      "id": 5,
      "firstName": "Jack",
      "lastName": "Rådahl",
      "level": "L",
      "shirtNumber": 94
    },
    {
      "id": 6,
      "firstName": "Jonathan",
      "lastName": "Moghtadai",
      "level": "L",
      "shirtNumber": 20
    },
    {
      "id": 7,
      "firstName": "Josef",
      "lastName": "Luodelahti",
      "level": "M",
      "shirtNumber": 9
    },
    {
      "id": 8,
      "firstName": "Love",
      "lastName": "Larsson",
      "level": "L",
      "shirtNumber": 95
    },
    {
      "id": 9,
      "firstName": "Ludwig",
      "lastName": "Kohlén",
      "level": "S",
      "shirtNumber": 47
    },
    {
      "id": 10,
      "firstName": "Mahmoud",
      "lastName": "Hassan",
      "level": "L",
      "shirtNumber": 99
    },
    {
      "id": 11,
      "firstName": "Malte",
      "lastName": "Menning",
      "level": "M",
      "shirtNumber": 22
    },
    {
      "id": 12,
      "firstName": "Nanne",
      "lastName": "Eruchalu",
      "level": "S",
      "shirtNumber": 8
    },
    {
      "id": 13,
      "firstName": "Obinna",
      "lastName": "Eruchalu",
      "level": "M",
      "shirtNumber": 13
    },
    {
      "id": 14,
      "firstName": "Oliver",
      "lastName": "Löwnander",
      "level": "S",
      "shirtNumber": 7
    },
    // {
    //   "id": 15,
    //   "firstName": "Olle",
    //   "lastName": "Malmborg",
    //   "level": "L",
    //   "shirtNumber": 6
    // },
    {
      "id": 16,
      "firstName": "Leon",
      "lastName": "Fredriksson",
      "level": "M",
      "shirtNumber": 15
    },
    {
      "id": 17,
      "firstName": "Oscar",
      "lastName": "Hybinette",
      "level": "S",
      "shirtNumber": 5
    },
    {
      "id": 18,
      "firstName": "Oskar",
      "lastName": "Nilsson Seing",
      "level": "L",
      "shirtNumber": 93
    },
    {
      "id": 19,
      "firstName": "Tage",
      "lastName": "Wallander",
      "level": "L",
      "shirtNumber": 98
    },
    {
      "id": 20,
      "firstName": "Tristan",
      "lastName": "Teike",
      "level": "L",
      "shirtNumber": 14
    },
    {
      "id": 21,
      "firstName": "Charlie",
      "lastName": "Johansson",
      "level": "M",
      "shirtNumber": 0
    },
    {
      "id": 22,
      "firstName": "Julian",
      "lastName": "Charpentier",
      "level": "M",
      "shirtNumber": 46
    },
    {
      "id": 23,
      "firstName": "Levi",
      "lastName": "Uppström",
      "level": "S",
      "shirtNumber": 18
    },
    {
      "id": 24,
      "firstName": "Benjamin",
      "lastName": "Harsanji",
      "level": "L",
      "shirtNumber": 3
    },
    // {
    //   "id": 25,
    //   "firstName": "Albin",
    //   "lastName": "Rahm",
    //   "level": "L",
    //   "shirtNumber": 16
    // },
    {
      "id": 26,
      "firstName": "Emil",
      "lastName": "Hellström",
      "level": "M",
      "shirtNumber": 75
    },
    {
      "id": 27,
      "firstName": "Jack",
      "lastName": "Bohman",
      "level": "L",
      "shirtNumber": 6
    }
  ]
};

export default playerData;
