interface Config {
  environment: string;
  recaptchaKey: string;
  apiUrl: string;
};


const getVariable = (name: string, defaultValue?: string) => {
  if (!process.env[name]) {
    console.error(`Environment variable "${name}" is missing! Returning ${defaultValue || 'EMPTY STRING'}`);
    return defaultValue || '';
  }
  return String(process.env[name]);
}

const environment = getVariable('NODE_ENV', 'development');
const recaptchaKey = getVariable('REACT_APP_RECAPTCHA_SITE_KEY');
const apiUrl = environment === 'production' ? getVariable('REACT_APP_API_URL', 'http://localhost:4444/api') : 'http://localhost:4444/api';

const config: Config = {
  environment,
  recaptchaKey,
  apiUrl,
};

export default config;
