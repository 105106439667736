import type { PlayerGroup } from '../models';
import axios from 'axios';
import config from '../utils/config';

const BASE_URL =
  config.environment !== 'production' ? 'http://localhost:4444' : '';

export const saveGroups = async (groups: PlayerGroup[]) => {
  try {
    await axios.post(`${BASE_URL}/api/groups`, {
      groups,
    });
  } catch (error) {
    console.error('Error saving group');
  }
};

export const getTodaysGroups = async () => {
  try {
    const { data } = await axios.get<{ date: string; groups: PlayerGroup[] }>(
      `${BASE_URL}/api/groups`
    );
    return data.groups;
  } catch (error) {
    console.error('Error fetching groups');
  }
};
